import React from "react";
import { Link, useStaticQuery, graphql  } from "gatsby";

// Components
import Layout from "../components/Layout";
import Seo from "../components/SearchEngineOptimalization";
import Header from "../components/Header";

// Images
import IconDocs from "../images/icon/docs.svg";
import IconExtension from "../images/icon/extension.svg";

export default function IndexPage() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          version
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Root - Thoughtful Laravel Admin Package" frontPage={true} />

      <div className="site-hero-wrapper" style={{backgroundImage: 'url(/hero-bg.jpg)'}}>
        <Header type="light" />
        <main className="container">
          <div className="site-hero">
            <h1 className="site-hero__title"><mark>Thoughtful</mark> Laravel <br /> Development</h1>
            <div className="site-hero__caption">
              <p className="lead site-hero__introduction">Root is an admin package for Laravel applications with extension support.</p>
              <div className="site-hero__meta">
                <strong>Version {site.siteMetadata.version}</strong>
                <span className="separator"></span>
                <Link to="/docs/core-concepts">Documentation</Link>
              </div>
            </div>
          </div>
          <div className="documentation-cards">
            <Link to="/docs/core-concepts" className="documentation-card">
              <IconDocs className="documentation-card__icon" />
              <h2 className="documentation-card__title">Core Concepts <span className="label label--docs">Docs</span></h2>
              <p>Root is an admin package for Laravel applications. It offers a simple model management with a robust resource API and a modern and fast UI.</p>
            </Link>
            <Link to="/docs/bazar/core-concepts" className="documentation-card">
              <IconExtension className="documentation-card__icon" />
              <h2 className="documentation-card__title">Bazar E-commerce <span className="label label--extension">Extension</span></h2>
              <p>Bazar is made to be compact and easy to integrate. This means our goal is to provide a simple solution for those who want to build a small to medium-sized e-commerce system.</p>
            </Link>
          </div>
        </main>
      </div>
    </Layout>
  );
}
